export const INITIAL_MESSAGES_FILTERS = {};
export const INITIAL_MESSAGES_PAGE = 1;
export const INITIAL_MESSAGES_SORT = ['CREATED_AT_DESC'];
export const INITIAL_MESSAGES_LIMIT = 50;

export const enum MessagesPermissions {
  READ_MESSAGE_DOWNLOAD_ATTACHMENTS_BUTTON = 'read_message_download_attachments_button',
  READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENTS_BUTTON = 'read_message_download_file_attachments_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_PRODUCTS_BUTTON = 'read_message_download_selected_products_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_PRODUCT_BUTTON = 'read_message_download_selected_product_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_LIFESTYLES_BUTTON = 'read_message_download_selected_lifestyles_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_LIFESTYLE_BUTTON = 'read_message_download_selected_lifestyle_button',
  READ_MESSAGE_FAVORITE_BUTTON = 'read_message_favorite_button',
  READ_MESSAGE_FLAGGED_BUTTON = 'read_message_flagged_button',
  READ_MESSAGE_FORWARD_BUTTON = 'read_message_forward_button',
  READ_MESSAGE_NEW_FORWARD_BUTTON = 'read_message_new_forward_button',
  READ_MESSAGE_RED_BUTTON = 'read_message_red_button',
  READ_MESSAGE_LIKES_BUTTON = 'read_message_likes_button',
  READ_MESSAGE_PINNED_BUTTON = 'read_message_pinned_button',
  READ_MESSAGE_PROPAGATE_BUTTON = 'read_message_propagate_button',
  READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON = 'read_message_visible_for_clients_button',
  READ_MESSAGE_CHECK_BILL_BUTTON = 'read_message_check_bill_button',
  READ_MESSAGE_CANCEL_BILL_BUTTON = 'read_message_cancel_bill_button',
  READ_MESSAGE_ADD_TO_DESCRIPTION_BUTTON = 'read_message_add_to_description_button',
  READ_MESSAGE_BILL_MENU_DROPDOWN = 'read_message_bill_menu_dropdown',
  READ_MESSAGE_LINK_BLANK = 'read_message_link_blank',
  READ_MESSAGE_ATTACH_MATERIALS_BUTTON = 'read_message_attach_materials_button',
  READ_MESSAGE_COPY_ID_BUTTON = 'read_message_copy_id_button',
  READ_MESSAGE_COPY_UUID_BUTTON = 'read_message_copy_uuid_button',
  READ_MESSAGE_ID = 'read_message_id',
  READ_MESSAGE_BILL = 'read_message_bill',
  READ_MESSAGE_INVOICE = 'read_message_invoice',
  READ_MESSAGE_INVOICE_ACTION = 'read_message_invoice_action',
  READ_MESSAGE_INVOICE_REFUND_BUTTON = 'read_message_invoice_refund_button',
  READ_MESSAGE_INVOICE_CHARGEBACK_BUTTON = 'read_message_invoice_chargeback_button',
  READ_MESSAGE_INVOICE_CANCEL_BUTTON = 'read_message_invoice_cancel_button',
  READ_MESSAGE_INVOICE_APPROVE_BUTTON = 'read_message_invoice_approve_button',
  READ_MESSAGE_INVOICE_CONFIRM_PAYMENT_BUTTON = 'read_message_invoice_confirm_payment_button',
  READ_MESSAGE_INVOICE_MAIL_BUTTON = 'read_message_invoice_mail_button',
  READ_MESSAGE_INVOICE_VOID_BUTTON = 'read_message_invoice_void_button',
  READ_MESSAGE_INVOICE_DOWNLOAD_BUTTON = 'read_message_invoice_download_button',
  READ_MESSAGE_INVOICE_TOGGLE_BUTTON = 'read_message_invoice_toggle_button',
  READ_MESSAGE_INVOICE_PAY_BUTTON = 'read_message_invoice_pay_button',
  READ_MESSAGE_INVOICE_AV_BILLING_INFO = 'read_message_invoice_av_billing_info',
  READ_MESSAGE_INVOICE_BILLING_INFO = 'read_message_invoice_billing_info',
  READ_MESSAGE_INVOICE_TEAM_UPSALERS = 'read_message_invoice_team_upsalers',
  READ_MESSAGE_INVOICE_ITEMS = 'read_message_invoice_items',
  READ_MESSAGE_INVOICE_NOTES = 'read_message_invoice_notes',
  READ_MESSAGE_INVOICE_TERMS = 'read_message_invoice_terms',
  READ_MESSAGE_INVOICE_PAYMENT_METHOD = 'read_message_invoice_payment_method',
  READ_MESSAGE_PAID_BILL = 'read_message_paid_bill',
  READ_MESSAGE_PAYMENT_PROCESSING = 'read_message_payment_processing',
  READ_MESSAGE_VISIBLE_FOR_CLIENT_ICON = 'read_message_visible_for_client_icon',
  READ_MESSAGE_FORWARD_MESSAGE = 'read_message_forward_message',
  READ_MESSAGE_RESULT_EDIT_BUTTON = 'read_message_result_edit_button',
  READ_MESSAGE_RESULT_SUBMIT_SOURCE_FILES_BUTTON = 'read_message_result_submit_source_files_button',
  READ_MESSAGE_RESULT_DOWNLOAD_BUTTON = 'read_message_result_download_button',
  READ_MESSAGE_RESULT_SEND_TO_CLIENT_BUTTON = 'read_message_result_send_to_client_button',
  READ_MESSAGE_RESULT_VISIBLE_FOR_CLIENT_BUTTON = 'read_message_result_visible_for_client_button',
  READ_MESSAGE_RESULT_IS_RESULT_BUTTON = 'read_message_result_is_result_button',
  READ_MESSAGE_RESULT_BUTTONS = 'read_message_result_buttons',
  READ_MESSAGE_RESULT_FIN_BUTTONS = 'read_message_result_fin_buttons',
  READ_MESSAGE_FILE_ATTACHMENT_EDIT_BUTTON = 'read_message_file_attachment_edit_button',
  READ_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON = 'read_message_file_attachment_submit_source_files_button',
  READ_SELF_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON = 'read_self_message_file_attachment_submit_source_files_button',
  READ_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON = 'read_message_file_attachments_submit_source_files_button',
  READ_SELF_MESSAGE_FILE_ATTACHMENTS_SUBMIT_SOURCE_FILES_BUTTON = 'read_self_message_file_attachments_submit_source_files_button',
  READ_MESSAGE_SEND_EMAIL_NOTIFICATION_BUTTON = 'read_message_send_email_notification_button',
  READ_MESSAGES_INDEX_PAGE = 'read_messages_index_page',
  READ_MESSAGES_SEARCH_FILTERS_POPOVER = 'read_messages_search_filters_popover',
  READ_MESSAGES_SAVED_FILTERS_MENU = 'read_messages_saved_filters_menu',
  READ_MESSAGES_TAG_IDS_FILTER = 'read_messages_tag_ids_filter',
  READ_MESSAGES_CHECKBOX_FILTERS = 'read_messages_checkbox_filters',
  READ_MESSAGES_STARRED_FILTER = 'read_messages_starred_filter',
  READ_MESSAGES_FLAGGED_FILTER = 'read_messages_flagged_filter',
  READ_MESSAGES_VISIBLE_FOR_CLIENT_FILTER = 'read_messages_visible_for_client_filter',
  READ_MESSAGES_WITH_ATTACHMENT_FILTER = 'read_messages_with_attachment_filter',
  READ_MESSAGES_TASK_MEMBER_FILTER = 'read_messages_task_member_filter',
  READ_MESSAGES_PROJECT_MEMBER_FILTER = 'read_messages_project_member_filter',
  READ_MESSAGES_CREATED_AT_FILTER = 'read_messages_created_at_filter',
  READ_MESSAGE_ID_FILTER = 'read_message_id_filter',
  READ_MESSAGES_USER_UUIDS_FILTER = 'read_messages_user_uuids_filter',
  READ_MESSAGES_MY_MESSAGES_FILTER_MENU = 'read_messages_my_messages_filter_menu',
  READ_MESSAGES_UNREAD_FILTER_MENU = 'read_messages_unread_filter_menu',
  READ_MESSAGES_VISIBLE_FOR_CLIENT_FILTER_MENU = 'read_messages_visible_for_client_filter_menu',
  READ_MESSAGES_ONLY_CLIENTS_FILTER_MENU = 'read_messages_only_clients_filter_menu',
  READ_MESSAGE_CLIENT_ICON = 'read_message_client_icon',
  READ_MESSAGE_RED_ICON = 'read_message_red_icon',
  READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENT_BUTTON = 'read_message_download_file_attachment_button',
  READ_MESSAGE_DELETE_FILE_ATTACHMENT_BUTTON = 'read_message_delete_file_attachment_button',
  READ_MESSAGE_DELETE_SELECTED_MATERIAL_BUTTON = 'read_message_delete_selected_material_button',
  READ_MESSAGE_DELETE_SELECTED_PRODUCT_BUTTON = 'read_message_delete_selected_product_button',
  READ_MESSAGE_DELETE_SELECTED_LIFESTYLE_BUTTON = 'read_message_delete_selected_lifestyle_button',
  READ_MESSAGE_DOWNLOAD_SELECTED_MATERIAL_BUTTON = 'read_message_download_selected_material_button',
  READ_MESSAGES_CREATE_MESSAGE_IN_TASK_BUTTON = 'read_messages_create_message_in_task_button',
  READ_MESSAGES_CREATE_MESSAGE_IN_PROJECT_BUTTON = 'read_messages_create_message_in_project_button',
  READ_MESSAGES_UNREAD_FILTER_MENU_COUNT = 'read_messages_unread_filter_menu_count',
  READ_MESSAGES_ONLY_CLIENTS_FILTER_MENU_COUNT = 'read_messages_only_clients_filter_menu_count',
  READ_MESSAGE_USER_MENTIONS = 'read_message_user_mentions',
  READ_MESSAGES_HEADER = 'read_messages_header',
  READ_MESSAGES_HEADER_VISIBLE_FOR_CLIENTS_FILTER = 'read_messages_header_visible_for_clients_filter',
  READ_MESSAGES_HEADER_AUTHORS_FILTER = 'read_messages_header_authors_filter',
  READ_MESSAGES_HEADER_PINNED_BUTTON = 'read_messages_header_pinned_button'
}
