import { useMemo } from 'react';
import { ClientError } from 'graphql-request';
import { useQuery } from 'react-query';

import {
  TotalsQueryOptions,
  TotalsQueryResponse
} from './useTotalsQuery.types';

import { fetchTotals } from '../baseActions/fetchTotals';

import { LocalForage } from '../../../../../../utils/LocalForage';

type TotalsQueryErrorType = Error | ClientError;

function useTotalsQuery<
  Keys extends string,
  FilterKeys extends string = `${Keys}Filters`
>({
  query,
  cacheKey,
  filters,
  options = {}
}: TotalsQueryOptions<Keys, FilterKeys>) {
  const localForageCacheKey = `${cacheKey}-index`;

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useQuery<TotalsQueryResponse<Keys> | null>(
      `${cacheKey}-placeholder`,
      () => LocalForage.getItem<TotalsQueryResponse<Keys>>(localForageCacheKey),
      {
        enabled: options.enabledPlaceholder
      }
    );

  const fullCacheKey = useMemo(() => {
    return [cacheKey, filters];
  }, [cacheKey, filters]);

  const { data, isFetched, isLoading, error, isPlaceholderData } = useQuery<
    TotalsQueryResponse<Keys>,
    TotalsQueryErrorType
  >(
    fullCacheKey,
    () =>
      fetchTotals({
        query,
        filters
      }),
    {
      enabled: options.enabled || placeholderDataFetched,
      cacheTime: options.cacheTime,
      staleTime: options.staleTime,
      refetchInterval: options.refetchInterval,
      keepPreviousData: options.keepPreviousData,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        return LocalForage.setItem<TotalsQueryResponse<Keys>>(
          localForageCacheKey,
          data
        );
      },
      placeholderData
    }
  );

  return {
    data,
    isFetched: placeholderDataFetched || isFetched,
    isLoading,
    error,
    isPlaceholderData
  };
}

export default useTotalsQuery;
