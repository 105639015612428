export enum CommonPermissions {
  READ_WORKERS_FULL_NAME_FILTER = 'read_workers_full_name_filter',
  READ_CLIENTS_FULL_NAME_FILTER = 'read_clients_full_name_filter',
  READ_TASKS_AND_PROJECTS_MENU_TASKS_LINK = 'read_tasks_and_projects_menu_tasks_link',
  READ_TASKS_AND_PROJECTS_MENU_PROJECTS_LINK = 'read_tasks_and_projects_menu_projects_link',
  READ_TASKS_AND_PROJECTS_MENU_MESSAGES_LINK = 'read_tasks_and_projects_menu_messages_link',
  READ_ATTACHMENTS_LIST_DATE_FIELD = 'read_attachments_list_date_field',
  READ_ATTACHMENTS_LIST_SIZE_FIELD = 'read_attachments_list_size_field',
  READ_ATTACHMENTS_LIST_USER_FIELD = 'read_attachments_list_user_field',
  READ_FOLDERS_LIST_FILE_COUNT_FIELD = 'read_folders_list_file_count_field',
  READ_FOLDERS_LIST_DATE_FIELD = 'read_folders_list_date_field',
  READ_FOLDERS_LIST_USER_FIELD = 'read_folders_list_user_field',
  READ_WORKSPACE_MENU_CREATE_PROJECT_IN_TEAM_LINK = 'read_workspace_menu_create_project_in_team_link'
}
